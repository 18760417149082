import React, { Component } from 'react';
import { Switch, Route, BrowserRouter as Router, } from "react-router-dom";
import Home from './Home/Home.js';
import NavBar from './NavBar/NavBar.js';
import Footer from './Footer/Footer.js';
import ContactUs from './ContactUs/ContactUs.js'
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

export default class App extends Component {
  constructor(props){
    super(props)
    this.state={
      displaySite: null,
    }
  }
  render() {
    return (
      <div className="App">
        {
          this.state.displaySite && this.state.displaySite === '2funky4u'
          ?
            <Router>
              <Route path='/' component={NavBar} />
              <Switch>
                <Route exact path='/contact-us' component={ContactUs} />
                <Route path='/' component={Home} />
                {/* <Route exact path='/login' component={AboutUs} /> */}
              </Switch>
              <Route path='/' component={Footer} />
            </Router>
          :
          <input onChange={this.onChangeInput}></input>
        }
        
      </div>
    );
  };

  onChangeInput = (e) => {
    this.setState({displaySite: e.target.value})
  }
};
