import React from 'react';
import { Link, useLocation } from "react-router-dom";

export default function NavBar () {
  const route = useLocation()

    return (
      <div className="site-nav">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light bg-light justify-content-between">
            <a className="navbar-brand branding-full" href="/home"><h1>Rico Mine Shaft Inn</h1></a>
            <a className="navbar-brand branding-initials" href="/home"><h1>RMSI</h1></a>
            <div className="pages-wrapper">
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <div className="navbar-nav mr-auto mt-2 mt-lg-0">
                  <a className="nav-item nav-link phone-link" href="tel:+1-970-967-4996">Call (970)967-4996</a>
                  <a className={route.pathname === "/" ? "nav-item nav-link active" : "nav-item nav-link"} href="/">Home {route.pathname === "/home" ? <span className="sr-only">(current)</span> : null}</a>
                  <Link className={route.pathname === "/contact-us" ? "nav-item nav-link active" : "nav-item nav-link"} to="/contact-us">Contact Us {route.pathname === "/contact-us" ? <span className="sr-only">(current)</span> : null}</Link>
                  {/* <a className="nav-item nav-link" href="https://reserve3.resnexus.com/resnexus/reservations/lodging/F32D4F3A-99A7-4A22-A6BF-C20B2B227049" rel="nofollow" >Rooms</a> */}
                  <div className="max-booking nav-item nav-link" id="max-booking-widget">Book Now</div>
                  {/* <a className="nav-item nav-link" href="https://reserve3.resnexus.com/resnexus/reservations/lodging/F32D4F3A-99A7-4A22-A6BF-C20B2B227049" rel="nofollow" >Book Now</a> */}
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    );

};