import React, { Component } from 'react';

export default class Footer extends Component {
  render() {
    return (
      <footer className="site-footer" style={{textAlign: 'center'}}>
        <hr className="hr-gradiant" />
        <div className="container flexed">
          <div className="col-sm flexed content-left">
            <div className="footer-phone-and-address-wrapper">
              <a className="footer-phone" href="tel:+1-970-967-4996">(970)967-4996</a>
              <br></br>
              <a href="mailto: ricomineshaftinn@gmail.com">ricomineshaftinn@gmail.com</a>
              <p>
                20 S Glasgow Ave
              </p>
              <p>
                Rico, CO 81332
                United States
              </p>
            </div>
          </div>
          <div className="col-sm">
          </div>
          <div className="col-sm flexed content-left">
            <div className="ooo-wrapper">
              <p>
                Mon:	9:00 AM – 8:00 PM
              </p>
              <p>
                Tue:	9:00 AM – 8:00 PM
              </p>
              <p>
                Wed:	9:00 AM – 8:00 PM
              </p>
              <p>
                Thu:	9:00 AM – 8:00 PM
              </p>
              <p>
                Fri:	9:00 AM – 8:00 PM
              </p>
              <p>
                Sat:	9:00 AM – 8:00 PM
              </p>
              <p>
                Sun:	9:00 AM – 8:00 PM
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  };
};