import React, { Component } from 'react';

export default class ContactUs extends Component{
  render() {
    return (
      <main className="contact-us">
        <div className="contact-details-wrapper container flexed">
          {this.renderPhoneAndAddress()}
          {this.renderBusinessHours()}
        </div>
        <div className="map-wrapper">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3157.186831814352!2d-108.03347758468476!3d37.691811079774745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x873eccc6a0f49d63%3A0xaa46598e8871801d!2sRico%20Mine%20Shaft%20Inn!5e0!3m2!1sen!2sus!4v1627441870418!5m2!1sen!2sus" width="100%" height="450" style={{border:'0px'}} title="rico-map" loading="lazy"></iframe>

        </div>
        {/* <div style={{backgroundImage:`url('https://maps.googleapis.com/maps/api/staticmap?scale=1&amp;size=1600x900&amp;style=feature:poi.business|visibility:off&amp;style=feature:water|visibility:simplified&amp;style=feature:road|element:labels.icon|visibility:off&amp;style=feature:road.highway|element:labels|saturation:-90|lightness:25&amp;format=jpg&amp;language=en&amp;region=US&amp;markers=color:0xddaa44|37.691555,-108.031407&amp;zoom=16&amp;client=google-presto&amp;signature=QPtG4_dXGwfwrVn-CsSyFUy3fIM')`}} title="Map showing business location."></div> */}
      </main>
    );
  };

  renderPhoneAndAddress = () =>{
    return(
      <div className="col-md-6">
        <h2>Contact</h2>
        <p>
          <a className="contact-us-phone" href="tel:+1-970-967-4996">Call (970)967-4996</a>
        </p>
        <h2>Adress</h2>
        <p className="p-top-line contact-us-address">
          20 S Glasgow Ave
        </p>
        <p className="contact-us-address">
          Rico, CO 81332
          United States
        </p>
        <p className="get-directions-button-wrapper">
          <a className="get-directions-button" href="https://www.google.com/maps/dir//Rico+Mine+Shaft+Inn/data=!4m8!4m7!1m0!1m5!1m1!1s0x873eccc6a0f49d63:0xaa46598e8871801d!2m2!1d-108.031407!2d37.691555" target="blank" data-tracking-element-type="6" jslog="56039; track:impression,click">Get Directions</a>
        </p>
      </div>
    )
  };

  renderBusinessHours = () =>{
    return(
      <div className="col-md-6">
        <h2>Business Hours</h2>
        {/* <div className="ooo-wrapper"> */}
          <p className="p-top-line business-hours-day">
            Mon:	9:00 AM – 8:00 PM
          </p>
          <p className="p-top-line business-hours-day">
            Tue:	9:00 AM – 8:00 PM
          </p>
          <p className="p-top-line business-hours-day">
            Wed:	9:00 AM – 8:00 PM
          </p>
          <p className="p-top-line business-hours-day">
            Thu:	9:00 AM – 8:00 PM
          </p>
          <p className="p-top-line business-hours-day">
            Fri:	9:00 AM – 8:00 PM
          </p>
          <p className="p-top-line business-hours-day">
            Sat:	9:00 AM – 8:00 PM
          </p>
          <p className="business-hours-day ">
            Sun:	9:00 AM – 8:00 PM
          </p>
        {/* </div> */}
      </div>
    )
  };
}